import React, { useState, useEffect } from 'react'
import Connection from '../components/booth/Connection'
import Upload from '../components/booth/Upload'
import Cart from '../components/booth/Cart'
import Email from '../components/booth/Email'
import Home from '../components/booth/Home'
import Payment from '../components/booth/Payment'
import LisPrint from '../components/booth/LisPrint'
import Loader from '../components/Loader'
import Gradient from '../components/Gradient'
import Error from '../components/Error'
import { findBooth, createSession, findSession, applyPromo, createPayment, updatePayment, logSession } from '../api'
import { preparePhoto } from '../utils'
import Name from '../components/booth/Name'

function Booth() {
  const [booth, setBooth] = useState(null)
  const [session, setSession] = useState(null)
  const [payment, setPayment] = useState(null)
  const [photos, setPhotos] = useState([])
  const [step, setStep] = useState(null)
  const [name, setName] = useState(null)

  useEffect(() => { fetchConfig() }, [])
  const fetchConfig = async () => {
    setBooth(await findBooth())
  }
  const startSession = async () => {
    setSession(await createSession())
    setStep('connection')
  }
  const checkConnected = async () => {
    await setSession(await findSession(session.id))
    if (session.connected) setStep('upload')
  }
  const tryCode = async promo => {
    const s = await applyPromo(session.code, promo)
    console.log(s)
    if (!s.error) setSession(s)
    return s
  }
  const checkPhotos = async () => {
    const s = await findSession(session.id)
    if (s.error) return
    if (s.photos.length === photos.length && s.photos.length > 0) return
    const newPhotos = await Promise.all(s.photos.filter(p => !photos.find(pp => pp.id === p.id)).map(preparePhoto))
    await setPhotos([...photos, ...newPhotos.map(p => ({ ...p, quantity: 1 }))])
    if (s.photos.length > 0 && step !== 'cart') setStep('cart')
  }
  const paid = async payment => {
    console.log(payment)
    setPayment(await createPayment({ ...payment, session: session.id, booth: booth.id, company: booth.company.id, promo: (session.promo || {}).id }))
    logSession(session.id, `payed`, payment)
    setStep('name')
  }
  const updateEmail = async (email, optin) => {
    await updatePayment(payment.id, { email, optin })
    setPayment(p => ({ ...p, email, optin }))
    logSession(session.id, `payed`, payment)
    setStep('print')
  }
  const endSession = async () => {
    logSession(session.id, 'cancelled')
    setStep(null)
    setPhotos([])
    setSession(null)
  }
  const cleanSession = async () => {
    logSession(session.id, 'end')
    setStep(null)
    setPhotos([])
    setSession(null)
    setPayment(null)
  }
  if (!booth) return <Loader />
  if (booth.error) return <Error>{booth.error}</Error>
  if (session && session.error) return <Error>{session.error}</Error>
  const orderedPhotos = photos && photos.sort((a, b) => a.id - b.id).filter(p => p.quantity > 0)
  switch (step) {
    case 'connection':
      return <Connection
        close={endSession}
        code={session.code}
        checkConnected={checkConnected} />
    case 'upload':
      return <Upload
        close={endSession}
        checkPhotos={checkPhotos} />
    case 'cart':
      return <Cart
        config={booth.config}
        close={endSession}
        code={session.code}
        promo={session.promo}
        photos={orderedPhotos}
        checkPhotos={checkPhotos}
        tryCode={tryCode}
        update={photo => setPhotos([...photos.filter(p => p.id !== photo.id), photo])}
        updateAll={props => setPhotos(photos.map(p => ({ ...p, ...props })))}
        pay={() => setStep('payment')}
        log={m => logSession(session.id, m)} />
    case 'payment':
      return <Payment
        config={booth.config}
        promo={session.promo}
        photos={orderedPhotos}
        paid={paid}
        log={m => logSession(session.id, m)} />
    case 'name':
      return <Name
        config={booth.config}
        updateName={n => { setName(n); setStep('email') }} />
    case 'email':
      return <Email
        config={booth.config}
        updateEmail={updateEmail} />
    case 'print':
      return <LisPrint
        booth={booth}
        photos={orderedPhotos}
        cleanSession={cleanSession}
        session={session}
        log={m => logSession(session.id, m)}
        email={payment.email}
        name={name} />
    default:
      return <Home startSession={startSession} config={booth.config} logo={booth.company.logo} />
  }
}

export default function BoothWithGradient() {
  return (
    <div>
      <style dangerouslySetInnerHTML={{
        __html: `
        html { font-size: 4.075vw }
        * { user-select: none; cursor: none }
      ` }} />
      <Gradient />
      <Booth />
    </div>
  )
}