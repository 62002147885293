import React, { useState } from 'react'
import Keyboard from '../Keyboard'
import Header from './Header'
import Title from '../Title'
import Button from '../Button'


export default function Name({ session, updateName }) {
  const [name, setName] = useState('')
  return (
    <div className="w-screen h-screen px-2 pt-24 pb-12 flex flex-col justify-between items-center">
      <Title className="text-center m-2">
        Entrez votre nom
      </Title>
      <Header
        title="Nom"
        subtitle="Je renseigne mon nom" />
      <Keyboard type="text" onType={v => setName(v)} />
      <div className="flex flex-row justify-center items-center">
        <Button
          className="m-4"
          onClick={() => updateName(name)}
          disabled={name.length < 1}>
          Valider
        </Button>
      </div>
    </div>
  )
}
