import React from 'react'
import { FiChevronDown } from 'react-icons/fi'

export default function Sample({ template, className, children }) {
  return (
    <div
      className={`relative flex justify-center items-center border-white bg-white bg-opacity-30 text-xs font-thin text-white ${className}`}
      style={{ width: `${4}rem`, height: `${4 * template.height / template.width}rem` }}>
      <svg className="absolute top-0 left-0 -mt-4 -ml-1 opacity-50" xmlns="http://www.w3.org/2000/svg" style={{ width: '3.5vw', height: '3.5vw' }} viewBox="0 0 22 24" fill="none" stroke="currentColor" ><polyline points="12 19 5 12 12 5"></polyline></svg>
      <svg className="absolute top-0 right-0 -mt-4 -mr-1 opacity-50" xmlns="http://www.w3.org/2000/svg" style={{ width: '3.5vw', height: '3.5vw' }} viewBox="0 0 26 24" fill="none" stroke="currentColor" ><polyline points="12 5 19 12 12 19"></polyline></svg>
      <svg className="absolute top-0 left-0 -ml-4 -mt-1 opacity-50" xmlns="http://www.w3.org/2000/svg" style={{ width: '3.5vw', height: '3.5vw' }} viewBox="0 0 24 22" fill="none" stroke="currentColor" ><polyline points="5 12 12 5 19 12"></polyline></svg>
      <svg className="absolute bottom-0 left-0 -ml-4 -mb-1 opacity-50" xmlns="http://www.w3.org/2000/svg" style={{ width: '3.5vw', height: '3.5vw' }} viewBox="0 0 24 26" fill="none" stroke="currentColor" ><polyline points="19 12 12 19 5 12"></polyline></svg>
      <div className="absolute top-0 left-0 -mt-6 w-full text-center" style={{ fontSize: '0.8em' }}>{Math.round(template.width / 300 * 2.54)} cm</div>
      <div className="absolute top-0 left-0 -mt-2 w-full bg-white opacity-50" style={{ height: '0.1vw', top: -3.5  }} />
      <div className="absolute top-0 left-0 text-center -ml-6" style={{ fontSize: '0.8em', transform: 'rotate(-90deg) translateX(-100%)', transformOrigin: '0 0', width: `${template.height / template.width * 100}%` }}>{Math.round(template.height / 300 * 2.54)} cm</div>
      <div className="absolute top-0 left-0 -ml-2 w-full bg-white opacity-50" style={{ height: '0.1vw', left: -3.5, fontSize: '0.8em', transform: 'rotate(-90deg) translateX(-100%)', transformOrigin: '0 0', width: `${template.height / template.width * 100}%` }} />
      <svg
        className="h-full"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${template.width} ${template.height}`}>
      </svg>
      {children}
    </div>
  )
}
