import React from 'react'
import Photo from './Photo'

export default function Editor({ photo, update }) {
  return (
    <div className="h-full w-full flex justify-center items-center flex-grow">
      <Photo
        photo={photo}
        style={{ width: 'auto', maxWidth: '80vw', height: 'auto', flexGrow: 1 }}
        onCrop={crop => update({ ...photo, crop })} />
    </div>
  )
}
