import React from 'react'
import Button from '../Button'
import { formatPrice } from '../../utils'

export default function Home({ startSession, config, logo }) {
  return (
    <div className="w-screen h-screen px-10 pb-32 pt-12 flex flex-col justify-between items-center">
      <div className="text-4xl text-center flex flex-col justify-between items-center font-medium" style={{ textShadow: '2px 2px 10px rgba(0, 0, 0, .1)' }}>
        {logo &&
          <div className="p-5 rounded-full mb-8 bg-white bg-opacity-30 flex justify-center items-center w-32 h-32" style={{ backdropFilter: 'blur(1rem)', boxShadow: '0 0 3rem rgba(0, 0, 0, .05)' }}>
            <img src={logo.url} className="w-full max-h-full" style={{ filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, .07))' }} />
          </div>
        }
        Imprimer les photos de mon téléphone
      </div>
      <div>
        <video className="absolute top-0 left-0 -z-10 opacity-75 w-full h-full" autoPlay loop muted>
          <source src="https://storage.googleapis.com/phoprint/video_447d0c220d/video.webm_video_447d0c220d.webm" type="video/webm" />
        </video>
        <Button
          variant="primary"
          onClick={startSession}>
          J'imprime mes photos
        </Button>
        {config.price > 0 &&
          <div className="text-xs text-center">Tarif {formatPrice(config.price)} par photo</div>
        }
      </div>
    </div>
  )
}
