import React from 'react'
import StackdriverErrorReporter from 'stackdriver-errors-js'

const location = () => typeof window !== `undefined` ? window.location : { pathname: '', hostname: '', search: '' }

if (typeof window !== `undefined` && window.location.hostname != 'localhost') {
  const match = location().search.match(/id=(.+)&token=(.+)/) || []
  const errorHandler = new StackdriverErrorReporter()
  errorHandler.start({
    key: 'AIzaSyA-O2pSyq3vkEkVzxpT1QAmqBqR6-sOAPE',
    projectId: 'wizito',
    service: 'Phoprint front',
    context: match ? { user: match[1] } : {}
  })
}

export const layoutStyle = 'w-screen h-screen p-10 pt-24 flex flex-col justify-center items-center'
export const layoutScrollStyle = 'w-screen h-screen p-4 pt-24 flex flex-col justify-start items-center overflow-y-scroll'

const genTemplate = (width, height) => ({ name: `${Math.round(width / 300 * 2.54)}x${Math.round(height / 300 * 2.54)}`, width, height, bleed: 0, photo: { x: 0, y: 0, width, height } })

export const templates = [
  genTemplate(945, 1180),
  genTemplate(1300, 1800),
  genTemplate(1535, 2000),
  genTemplate(1770, 2360),
  genTemplate(2360, 3540),
  genTemplate(3602, 5315)
]

export function DisableBodyScroll() {
  return <style dangerouslySetInnerHTML={{ __html: 'body { overflow: hidden } .overflow-y-scroll { overflow: hidden !important; height: 100% }' }} />
}

export function formatPrice(price) {
  if (!price) return ''
  const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })
  if (price.unit_amount === 0) return 'Offert'
  return `${formatter.format((price.unit_amount || price) / 100.0)}`
}

export function computePrice(promo, photos, config) {
  return photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0) * config.price - ((promo || {}).amount || 0)
}

export async function preparePhoto(photo) {
  return { ...photo, quantity: 1, template: templates[1] }
}

export function exportSvg(node) {
  if (!node) return Promise.resolve()
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL
    const svgString = new XMLSerializer().serializeToString(node)
    const canvas = document.createElement('canvas')
    canvas.width = node.viewBox.baseVal.width
    canvas.height = node.viewBox.baseVal.height
    const ctx = canvas.getContext('2d')
    const img = new Image()
    const svg = new Blob([svgString], { type: 'image/svg+xml' })
    const url = URL.createObjectURL(svg)
    img.onload = () => {
      if (ctx) ctx.drawImage(img, 0, 0)
      //
      // The safari tricks was found on this link https://mybyways.com/blog/convert-svg-to-png-using-your-browser
      //
      const safariTricks = document.createElement('a')
      document.body.appendChild(safariTricks)
      safariTricks.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(safariTricks)
      resolve(canvas.toDataURL(`image/jpeg`))
    }
    img.src = url
  })
}

export function groupBy(tab, format) {
  return tab.reduce((acc, obj) => {
    let key = obj[format]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj);
    return acc;
  }, {})
}

console.log(templates)