import React, { useEffect, useState } from 'react'
import Header from './Header'
import { layoutStyle, groupBy } from '../../utils'
import Title from '../Title'
import Button from '../Button'

export default function Print({ photos, booth, cleanSession, session, name, email }) {
  const [thanks, setThanks] = useState(false)
  const [error, setError] = useState(false)
  const prints = photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0)
  const send = () => {
    setError(false)
    return fetch('https://europe-west1-wizito.cloudfunctions.net/phoprint-lis-prod', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ photos: photos.map(p => ({ url: p.url, template: p.template.name, quantity: p.quantity })), booth, session, name, email })
    })
    .then(_ => setTimeout(() => setThanks(true), 4000))
    .catch(e => setError(e))
  }
  useEffect(() => {
    send()
  }, [])
  useEffect(() => {
    if (thanks)
      setTimeout(cleanSession, 7000)
  }, [thanks])
  if (error) 
    return (
      <div className={layoutStyle}>
        <Header
          title="Envoie des photos"
          subtitle="Erreur" />
        <Title className="text-center">Une erreur est survenue pendant l'envoie des phtoos</Title>
        <p className="text-center mt-4">
          <Button onClick={send}>Réessayer</Button>
        </p>
      </div>
    )
  if (thanks)
    return (
      <div className={layoutStyle}>
        <Header
          title="Envoie des photos"
          subtitle="Transfert terminé" />
        <Title className="text-center">Merci, vos photos ont été transmises</Title>
        <p className="text-center">Votre numéro de commande est le <strong>{session.code}</strong>, vous pouvez rendre au comptoir pour l'impression.</p>
      </div>
    )
  return (
    <div className={layoutStyle}>
      <Header
        title="Envoie des photos"
        subtitle="Encore un tout petit peu de patience" />
      <Title>Envoi de {prints} photos</Title>
      <p>Votre numéro de commande est le <strong>{session.code}</strong></p>
    </div>
  )
}
