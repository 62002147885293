import React, { useState } from 'react'
import Keyboard from '../Keyboard'
import Header from './Header'
import Title from '../Title'
import Checkbox from '../Checkbox'
import Button from '../Button'

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/

export default function Email({ session, updateEmail }) {
  const [optin, setOptin] = useState(false)
  const [email, setEmail] = useState('')
  return (
    <div className="w-screen h-screen px-2 pt-24 pb-12 flex flex-col justify-between items-center">
      <Title className="text-center m-2">
        Entrez votre email pour rester en contact
      </Title>
      <Header
        title="Email"
        subtitle="Je renseigne mon email" />
      <Keyboard type="email" onType={v => setEmail(v)} />
      <div className="flex flex-row p-4 items-center">
        <Checkbox
          checked={optin}
          onClick={() => setOptin(!optin)}
          className="ml-2 mr-4" />
        <p
          className="leading-tight text-sm"
          onClick={() => setOptin(!optin)}>
          J’accepte de recevoir des informations commerciales de la part de LIS Photo
        </p>
      </div>
      <div className="flex flex-row justify-center items-center">
        <Button
          className="m-4"
          onClick={() => updateEmail(null, null)}>
          Passer
        </Button>
        <Button
          className="m-4"
          onClick={() => updateEmail(email, optin)}
          disabled={!email.match(emailRegex)}>
          Valider
        </Button>
      </div>
    </div>
  )
}
