import React, { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Button from '../Button'
import Title from '../Title'
import Popup from '../Popup'

export default function Header({ title, subtitle, close }) {
  const [confirm, setConfirm] = useState(false)
  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen flex justify-between items-center pl-8 pr-4 py-3 bg-white bg-opacity-20 border-white border border-opacity-20 z-10"
        style={{ backdropFilter: 'blur(1rem)', boxShadow: '0 0 3rem rgba(0, 0, 0, .1)', textShadow: '2px 2px 2px rgba(0, 0, 0, .1)' }}>
        <div className="leading-6">
          <Title>{title}</Title>
          <div className="font-thin">{subtitle}</div>
        </div>
        {close && <FiX onClick={() => setConfirm(true)} className="h-full w-8 stroke-1" />}
      </div>
      {confirm &&
        <Popup close={() => setConfirm(false)}>
          <Title>Voulez-vous vraiment abandonner votre commande ?</Title>
          <div className="flex mt-8">
            <Button className="mx-4" onClick={close}>J'abandonne</Button>
            <Button className="mx-4" onClick={() => setConfirm(false)}>Annuler</Button>
          </div>
        </Popup>
      }
    </>
  )
}
