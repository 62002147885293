import React, { useState, useRef, useEffect } from 'react'
import { formatPrice, computePrice, templates } from '../../utils'
import useInterval from '@use-it/interval'
import Header from './Header'
import Photo from './Photo'
import Button from '../Button'
import Popup from '../Popup'
import Keyboard from '../Keyboard'
import Editor from './Editor'
import Sample from './Sample'
import { FiTrash2, FiZoomIn, FiChevronDown, FiGift, FiDelete, FiCheck, FiArrowLeft } from 'react-icons/fi'
import { useGesture } from 'react-use-gesture'
import QRcode from 'qrcode.react'
import Title from '../Title'

const location = () => typeof window !== `undefined` ? window.location : { pathname: '', origin: '', search: '' }

const url = location().origin

function Formats({ photo, applyFormat, className }) {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(photo.template)
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className={`bg-opacity-20 bg-white pl-1 pr-0 border-white border border-opacity-20 flex justify-between items-center leading-none text-xs ${className}`}>
        {photo.template.name}
        <FiChevronDown className="stroke-1 ml-0" />
      </div>
      {open &&
        <Popup close={() => setOpen(false)} title="Choisissez le format">
          <div className="flex flex-wrap justify-center items-end -mx-2 mt-2">
            {templates.map(template =>
              <div key={template.name} className="relative" onClick={() => setCurrent(template)}>
                <svg
                  className="mx-3 mt-10"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox={`0 0 ${template.width} ${template.height}`}
                  style={{ width: `${template.width / 120}vw` }}> 
                  <rect x={0} y={0} width={template.width} height={template.height} fill="white" fillOpacity={template.name === current.name ? 0.9 : 0.3} />
                </svg>
                <div className={`${template.name === current.name ? 'font-bold' : 'font-thin'} text-sm mt-1`}>
                  {template.name}
                  {template.name === '11x15' &&
                    <div className="absolute font-thin leading-none text-xs">Format standard</div>
                  }
                </div>
              </div>
            )}
          </div>
          <div className="flex mt-10">
            <Button className="m-2" onClick={() => { applyFormat(current); setOpen(false) }}>Valider</Button>
          </div>
        </Popup>
      }
    </>
  )
}


function Quantity({ photo, applyQuantity, remove }) {
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(false)
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="bg-opacity-20 bg-white pl-1 pr-0 border-white border border-opacity-20 flex justify-between items-center leading-none text-xs">
        {photo.quantity}
        <FiChevronDown className="stroke-1 ml-0" />
      </div>
      {open &&
        <Popup close={() => setOpen(false)} title="Choisissez la quantité">
          <div className="flex justify-between my-4 w-48 px-2 items-center">
            <div className="text-3xl h-12">{quantity || ''}</div>
            <Button variant="icon" className="ml-3 transform scale-90" Icon={FiDelete} onClick={() => { setQuantity(null) }} />
          </div>
          <div className="flex flex-wrap justify-center items-center mx-16">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(q =>
              <Button
                key={q}
                variant="icon"
                className="m-3 text-lg font-normal transform scale-125"
                onClick={() => { setQuantity(parseInt(`${quantity || ''}${q}`)) }}>
                {`${q}`}
              </Button>
            )}
          </div>
          <div className="flex mt-10">
            <Button className="m-2" onClick={() => { applyQuantity(quantity || 1); setOpen(false); setQuantity(null) }}>Valider</Button>
            <Button variant="icon" Icon={FiTrash2} className="m-2 text-white" onClick={() => { remove(); setOpen(false); setQuantity(null) }}></Button>
          </div>
        </Popup>
      }
    </>
  )
}

function Delete({ remove }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className="bg-opacity-20 bg-white px-1 border-white border border-opacity-20 flex justify-between items-center leading-none text-xs">
        <FiTrash2 className="stroke-1 w-3" style={{ width: '0.6rem' }} />
      </div>
      {open &&
        <Popup close={() => setOpen(false)} title="Voulez-vous vraiment supprimer cette photo ?">
          <div className="flex mt-10">
            <Button className="m-2" onClick={() => { remove(); setOpen(false) }}>Supprimer</Button>
            <Button className="m-2" onClick={() => { setOpen(false) }}>Annuler</Button>
          </div>
        </Popup>
      }
    </>
  )
}

function PhotoContainer({ photo, update, size, log }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Photo photo={photo} size={size} onClick={() => setOpen(true)} />
      <div
        className="w-full px-1 py-1 bg-opacity-20 bg-white border-white border border-opacity-20 flex justify-between items-center"
        style={{ boxShadow: '0 0 3rem rgba(0, 0, 0, .05)', textShadow: '2px 2px 2px rgba(0, 0, 0, .05)' }}>
        <Formats
          photo={photo}
          applyFormat={template => {
            update({ ...photo, template })
            log(`update photo template to ${template.name}`)
          }} />
        <Quantity
          photo={photo}
          applyQuantity={quantity => {
            update({ ...photo, quantity })
            log(`update photo quantity to ${quantity}`)
          }}
          remove={() => {
            update({ ...photo, quantity: 0 })
            log('remove photo')
          }} />
        <Delete
          photo={photo}
          remove={() => {
            update({ ...photo, quantity: 0 })
            log('remove photo')
          }} />
        {open &&
          <Popup close={() => setOpen(false)}>
            <Editor photo={photo} update={update} />
          </Popup>
        }
      </div>
    </>
  )
}

function PromoContainer({ tryCode, promo, free, log }) {
  const [code, setCode] = useState('')
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const apply = async () => {
    log(`try promo code ${code}`)
    const res = await tryCode(code)
    if (res.error) return setError(res.error)
    setOpen(false)
  }
  if (!promo && free) return null
  return (
    <div className="fixed bottom-0 left-0 h-32 w-full flex justify-center items-center pointer-events-none z-20">
      {!promo &&
        <Button
          variant="icon"
          className="ml-56 mb-4"
          Icon={FiGift}
          onClick={() => setOpen(true)} />}
      {promo &&
        <Button
          variant="secondary"
          className="mb-20 bg-opacity-30 font-normal"
          Icon={FiGift}
          onClick={() => setOpen(true)}>
          {free ? <>&nbsp;Gratuit</> : <>&nbsp;{formatPrice(-promo.amount)}</>}
        </Button>
      }
      {open &&
        <Popup close={() => setOpen(false)} title="Entrez votre code de réduction">
          <Keyboard onType={setCode} />
          <Button className="mt-6" onClick={apply}>Valider</Button>
          {error && <div>{error}</div>}
        </Popup>
      }
    </div>
  )
}
const windowHeight = () => typeof window !== `undefined` ? window.innerHeight : 0

function ScrollPane({ children, photos }) {
  const ref = useRef(null)
  const [height, setHeight] = useState(0)
  const hasDragged = React.useRef(false)
  useEffect(() => {
    setTimeout(() =>
      setHeight((ref.current && ref.current.children[0]) ? ref.current.children[0].getBoundingClientRect().height : 0)
    , 500)
  }, [photos])
  const bind = useGesture(
    {
      onDrag: ({ first, last, movement, memo }) => {
        if (first) {
          hasDragged.current = true
          memo = [ref.current.scrollTop]
        }
        if (last) {
          if (Math.abs(movement[0]) < 40 && Math.abs(movement[1]) < 40)
            hasDragged.current = false
          setTimeout(() => (hasDragged.current = false), 0)
        }
        if (Date.now() % 3 === 0 && memo !== undefined)
          ref.current.scroll(0, -Math.min(Math.max(-memo[0] + movement[1], -height -(windowHeight() / 2)), 0))
        return memo
      },
      onClickCapture: event => {
        if (hasDragged.current) event.stopPropagation()
      }
    }, { drag: { filterTaps: true } })
  return (
    <div
      {...bind()}
      className="w-screen h-screen overflow-y-scroll"
      ref={ref}>
      {children}
    </div>
  )
}

function PrintConfirm({ confirm, canceled }) {
  return (
    <Popup close={canceled} title="C'est tout bon, on imprime ?">
      <div className="flex">
        <Button className="m-2 text-white" Icon={FiArrowLeft} onClick={canceled}>Je re vérifie</Button>
        <Button className="m-2" Icon={FiCheck} onClick={confirm}>Valider</Button>
      </div>
    </Popup>
  )
}

export default function Cart({ close, promo, photos, update, updateAll, checkPhotos, tryCode, config, pay, log, code }) {
  const [printConfirm, setConfirm] = useState(false)
  useInterval(checkPhotos, 3000)
  const free = computePrice(promo, photos, config) <= 0
  const prints = photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0)
  const mainFormat = templates.map(t => ({ ...t, times: photos.filter(p => p.template.name === t.name).length })).sort((a, b) => b.times - a.times)[0]
  return (
    <ScrollPane photos={photos}>
      <div className="min-h-screen p-4 pt-24 flex flex-col justify-start items-center box-content">
        <Header
          title="Photos"
          subtitle=""
          close={close} />
        <div className="flex flex-wrap items-center justify-evenly mb-40 select-none">
          <div className="flex justify-center">
            <div className="text-center flex flex-col justify-center items-center w-1/3 mb-2 mx-2">
              <QRcode value={`${url}/?code=${code}`} size={128} level="L" includeMargin={false} fgColor="#e86e29" renderAs="svg" className="border-white bg-white h-20 w-20 border-8 p-1 m-2 opacity-50 rounded" />
              <div className="leading-none opacity-75" style={{ fontSize: '0.6rem' }}>Ajoutez d'autres photos depuis votre téléphone</div>
            </div>
            <div className="text-center flex flex-col justify-center items-center w-1/3 mb-2 mx-2">
              <Sample template={mainFormat} className="m-2">
                <div className="absolute flex flex-col justify-center items-center">
                  <Formats photo={{ template: mainFormat }} applyFormat={template => updateAll({ template })} className="m-2" />
                  <Quantity photo={{ template: mainFormat, quantity: photos[0] ? photos[0].quantity : 1 }} applyQuantity={quantity => updateAll({ quantity })} className="m-2" />
                </div>
              </Sample>
              <div className="leading-none opacity-75" style={{ fontSize: '0.6rem' }}>Modifiez le format et le nombre d'impression</div>
            </div>
          </div>
          {photos.map(photo =>
            <div key={photo.id} className="m-2">
              <PhotoContainer
                photo={photo}
                update={update}
                log={log}
                size={325} />
            </div>
          )}
          {photos.length === 0 &&
            <div className="h-64 flex flex-col justify-center items-center text-center">
              <div>Vous n'avez actuellement aucune photo.</div>
              <div>Ajoutez des photos depuis votre téléphone ou finalisez votre sessions avec la croix en haut à droite.</div>
            </div>
          }
        </div>
        <PromoContainer tryCode={tryCode} promo={promo} free={free} log={log} />
        {printConfirm &&
          <PrintConfirm confirm={pay} canceled={() => setConfirm(false)} />
        }
        {photos.length > 0 &&
          <div className="fixed bottom-0 h-32 w-screen flex flex-col justify-center items-center pointer-events-none">
            {!free &&
              <Button
                variant="primary"
                className="z-10"
                onClick={pay}>
                Payer {formatPrice(computePrice(promo, photos, config))}
              </Button>
            }
            {free &&
              <Button
                variant="primary"
                className="z-10 py-2"
                onClick={() => setConfirm(true)}>
                <div className="leading-none">
                  <div>Imprimer</div>
                  <div className="text-sm font-light">{prints} photo{prints > 1 && 's'}</div>
                </div>
              </Button>
            }
            {config.price > 0 &&
              <span className="text-xs font-thin">
                {formatPrice(config.price)} par photo
              </span>
            }
          </div>
        }
      </div>
    </ScrollPane>
  )
}
