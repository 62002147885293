import React, { useEffect } from 'react'
import { FiX } from 'react-icons/fi'
import Title from './Title'

export default function Popup({ children, close, title }) {
  return (
    <>
      <div
        className="fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-30 pointer-events-auto">
        <div
          onClick={e => close()}
          className="fixed h-screen w-screen top-0 left-0 z-20"
          onTouchMove={e => { e.preventDefault(); e.stopPropagation(); return false }}
          style={{ backdropFilter: 'blur(1rem)' }} />
        <FiX
          onClick={e => close()}
          className="fixed top-0 right-0 m-4 w-8 h-8 cursor-pointer stroke-1 z-50" />
        <div className="relative w-full h-auto p-4 z-30 flex flex-col justify-between items-center flex-wrap text-center">
          {title && <Title className="mb-4">{title}</Title>}
          {children}
        </div>
      </div>
    </>
  )
}
