import React, { useRef, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

const landscape = t => ({ ...t, width: t.height, height: t.width, photo: { x: 0, y: 0, width: t.height, height: t.width } })
const portrait = template => template

const defaultCrop = (image, template) => {
  const imageRatio = image.width / image.height
  const formatRatio = template.photo.width / template.photo.height
  const width = imageRatio > formatRatio ? image.height * formatRatio : image.width
  const height = imageRatio > formatRatio ? image.height : image.width / formatRatio
  const x = imageRatio > formatRatio ? (image.width - width) / 2 : 0
  const y = imageRatio > formatRatio ? 0 : (image.height - height) / 2
  const xLimits= [0, x * 2]
  const yLimits = [0, y * 2]
  return { x, y, width, height, xLimits, yLimits }
}

export default function Photo({ photo, className, onCrop, style, onClick, svgRef, render = false }) {
  const template = photo.width > photo.height ? landscape(photo.template) : portrait(photo.template)
  const templateToPhotoRatio = photo.width / template.width
  const photoRef = useRef(null)
  const crop = photo.crop || defaultCrop(photo, template)
  // let bind = useDrag(({ active, movement }) => {
  //   const x = Math.min(Math.max(crop.x - movement[0], crop.xLimits[0]), crop.xLimits[1])
  //   const y = Math.min(Math.max(crop.y - movement[1], crop.yLimits[0]), crop.yLimits[1])
  //   if (Date.now() % 3 === 0)
  //     photoRef.current.setAttribute('viewBox', `${x} ${y} ${crop.width} ${crop.height}`)
  //   if (!active)
  //     onCrop({ x, y, width: crop.width, height: crop.height, xLimits: crop.xLimits, yLimits: crop.yLimits })
  // })
  // if (!onCrop) bind = () => ({})
  const scale = (photo.width + photo.height) / 2
  const bleed = render ? 0 : template.bleed
  return (
    <svg
      ref={svgRef}
      onClick={onClick}
      className={className}
      viewBox={`0 0 ${photo.width / templateToPhotoRatio} ${photo.height / templateToPhotoRatio}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ width: `${photo.width / scale * 30}vw`, height: `${photo.height / scale * 30}vw`, ...style }}>
      <image
        xlinkHref={render ? photo.base64 : photo.url}
        preserveAspectRatio="xMidYMid slice"
        x={0 / templateToPhotoRatio}
        y={0 / templateToPhotoRatio}
        width={photo.width / templateToPhotoRatio}
        height={photo.height / templateToPhotoRatio} />
      <path d={`M -10 -10 L -10 ${photo.height / templateToPhotoRatio + 10} L ${photo.width / templateToPhotoRatio + 10} ${photo.height / templateToPhotoRatio + 10} L ${photo.width / templateToPhotoRatio + 10} -10 M ${crop.x / templateToPhotoRatio + bleed} ${crop.y / templateToPhotoRatio + bleed} l 0 ${crop.height / templateToPhotoRatio - bleed * 2} l ${crop.width / templateToPhotoRatio - bleed * 2} 0 l 0 ${-crop.height / templateToPhotoRatio + bleed * 2} l ${-crop.width / templateToPhotoRatio + bleed * 2} 0`}  fillRule="evenodd" clipRule="evenodd" fill="rgba(255, 255, 255, 0.5)"
      stroke="white"
      strokeWidth="5"
      strokeDasharray="30" />
    </svg>
  )
}
